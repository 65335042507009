export default (axios) => ({

  /*
  NOTES:

    1. I don't like that the query methods have generic params, not concrete objects (devs. have to look up the .NET code as well)

  */
  async getClientsToPolicies (carrierFilter = undefined) {
    const response = await axios.get('/exceptions/clients-to-policies', { params: { carrierFilter: carrierFilter } })
    return response.data
  },

  async getCarriers (queryParams) {
    const response = await axios.get('/exceptions/carriers')
    return response.data
  },

  async getPolicyCountSimpleReport (queryParams) {
    const response = await axios.get('/exceptions/policy-count-exceptions/report-simple', { params: queryParams })
    return response.data
  },

  async getExceptionClientReport (queryParams) {
    const response = await axios.get('/exceptions/client-exceptions/report', { params: queryParams })
    return response.data
  },

  async getExceptionCashSurrenderClientReport (queryParams) {
    const response = await axios.get('/exceptions/cash-surrender-exceptions/report', { params: queryParams })
    return response.data
  },

  async checkExceptionReportData () {
    const response = await axios.get('/exceptions/client-exceptions/check')
    return response.data
  },

  async checkExceptionCashSurrenderReportData () {
    const response = await axios.get('/exceptions/cash-surrender-exceptions/check')
    return response.data
  },

  async checkPolicyCountReportData () {
    const response = await axios.get('/exceptions/policy-count-exceptions/check')
    return response.data
  }

})
